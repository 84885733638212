.newSearchUnifiedNav-searchDropdown {
    display: flex;
    align-items: center;
    background: #36414D;
    height: 26px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'inter', sans-serif;
    z-index: 10;
    white-space: nowrap
}

.newSearchUnifiedNav-searchDropdown:focus {
  outline-offset: 2px;
  outline: #007AF5 solid 2px;
}

.newSearchUnifiedNav-searchDropdown .allLabel {
    padding-right: 4px;
}

.newSearchUnifiedNav-searchDropdown .contentTypeSelector {
    visibility: hidden
}

.newSearchUnifiedNav-searchDropdown .Dropdown-placeholder {
    font-weight: 600;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-menu {
  padding: 18px 0;
  border-radius: 24px;
  min-width: 210px;
  border-width: 1px;
  overflow: visible;
  max-height: none;
  font-size: 14px;
  position: absolute;
  background-color: white;
  margin-left: -18px;
  margin-top: 24px;
  color: #1f2830;
  border-color: rgb(185, 188, 193);
  z-index: 1000;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-menu .contentClassIcon {
    padding-left: 11px;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-placeholder .allLabel {
    display: none;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-placeholder .contentTypeSelector {
    visibility: visible;
}

.newSearchUnifiedNav-searchDropdown .contentTypeLabel {
    display: flex;
    align-items: center;
    line-height: 20px;
    font-size: 14px;
}

.newSearchUnifiedNav-searchDropdown .contentClassIcon {
    margin-bottom: 3px;
    height: 20px;
    margin-right: 2px;
}

.newSearchUnifiedNav-searchDropdown .contentClassIcon svg {
    padding-right: 2px;
    height: 20px;
    width: 20px;
    margin-right: 2px;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-arrow-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-arrow {
    display: block;
    margin: 2px auto;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    display: block;
    height: 0;
    position: absolute;
    right: 10px;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-root .Dropdown-placeholder,
.newSearchUnifiedNav-searchDropdown .Dropdown-root .Dropdown-placeholder.is-selected .contentTypeLabel {
    color: white;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-root .Dropdown-placeholder.is-selected svg path {
    fill: white;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-root.is-open .Dropdown-arrow {
    border-width: 0 5px 5px;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-root.is-focused:not(.is-open) > .Dropdown-control {
    box-shadow: none;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-control {
    width: auto;
    padding-right: 2px;
    color: #1f2830;
    display: flex;
    flex: 1;
    align-items: center;
    border: transparent;
    background: transparent;
    position: relative;
    box-sizing: border-box;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-option {
    padding: 6px 10px;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-option.is-selected,
.newSearchUnifiedNav-searchDropdown .Dropdown-option.is-focused {
    background-color: #ffffff;
}

.newSearchUnifiedNav-searchDropdown select {
    border: none;
    background-color: transparent;
    border-radius: 0;
    height: 100%;
    font-size: 14px;
    padding: 0 20px 0 8px;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 64 64"><g><path fill="black" d="M32,39.6L7.9,15.4c-1.8-1.8-4.7-1.8-6.5,0c-1.8,1.8-1.8,4.8,0,6.6l27.2,27.3c1,1,2.2,1.4,3.5,1.3   c1.3,0.1,2.5-0.4,3.5-1.3l27.2-27.3c1.8-1.8,1.8-4.8,0-6.6c-1.8-1.8-4.7-1.8-6.5,0L32,39.6z"/></g></svg>');
    background-position: calc(100% - 8px) calc(1em / 2 + 2px);
    background-size: 8px 8px;
    background-repeat: no-repeat;
}

.newSearchUnifiedNav-searchDropdown select:active {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 64 64"><g><path fill="black" d="M32,25l24.1,24.3c1.8,1.8,4.7,1.8,6.5,0c1.8-1.8,1.8-4.8,0-6.6L35.5,15.3c-1-1-2.2-1.4-3.5-1.3   c-1.3-0.1-2.5,0.4-3.5,1.3L1.4,42.6c-1.8,1.8-1.8,4.8,0,6.6c1.8,1.8,4.7,1.8,6.5,0L32,25z"/></g></svg>');
    outline: 0;
    box-shadow: none;
}

.newSearchUnifiedNav-searchDropdown select::-ms-expand {
    display: none;
}

.newSearchUnifiedNav-searchDropdown select:-moz-focusring {
    color: transparent;
    text-shadow: black;
}

/* Prominent search dropdown  */

.newSearchUnifiedNav-searchDropdown .Dropdown-placeholder .contentClassIcon {
    display: none;
}

.Dropdown-placeholder .beta-badge {
  display: none;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-root:not(.is-disabled) .Dropdown-control:hover {
    background-color: transparent;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-control svg path {
    fill: #ffffff;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-control .contentTypeLabel {
    font-size: 16px;
    z-index: 10;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-control .Dropdown-arrow-wrapper {
    font-size: 28px;
    display: flex;
    flex: 1;
    align-items: center;
    padding-left: 10px;
    z-index: 10;
}

.newSearchUnifiedNav-searchDropdown .Dropdown-control:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.unifiedNav-searchDropdown {
  display: flex;
  align-items: center;
  background: transparent;
  height: 26px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'inter', sans-serif;
  z-index: 10;
}

.unifiedNav-searchDropdown .allLabel {
  padding-right: 4px;
}

.unifiedNav-searchDropdown .contentTypeSelector {
  visibility: hidden
}

.unifiedNav-searchDropdown .Dropdown-placeholder {
  font-weight: 600;
}

.unifiedNav-searchDropdown .Dropdown-menu .contentClassIcon {
  padding-left: 11px;
}

.unifiedNav-searchDropdown .Dropdown-placeholder .allLabel {
  display: none;
}

.unifiedNav-searchDropdown .Dropdown-placeholder .contentTypeSelector {
  visibility: visible;
}

.unifiedNav-searchDropdown .contentTypeLabel {
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 14px;
  color: #4a4a4a;
}

.unifiedNav-searchDropdown .contentClassIcon {
  margin-bottom: 3px;
  height: 20px;
  margin-right: 2px;
}

.unifiedNav-searchDropdown .contentClassIcon svg {
  padding-right: 2px;
  height: 20px;
  width: 20px;
  margin-right: 2px;
}

.unifiedNav-searchDropdown .contentTypeSelector {
  visibility: hidden;
}

.unifiedNav-searchDropdown .Dropdown-arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unifiedNav-searchDropdown .Dropdown-arrow {
  display: block;
  margin: 2px auto;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  display: block;
  height: 0;
  position: absolute;
  right: 10px;
}

.unifiedNav-searchDropdown .Dropdown-root.is-open .Dropdown-placeholder.is-selected .contentTypeLabel {
  color: #ff0089;
}

.unifiedNav-searchDropdown .Dropdown-root.is-open .Dropdown-placeholder.is-selected svg path {
  fill: #ff0089;
}

.unifiedNav-searchDropdown .Dropdown-root.is-open .Dropdown-arrow {
  border-bottom-color: #ff0089;
  border-width: 0 5px 5px;
}

.unifiedNav-searchDropdown .Dropdown-root.is-focused:not(.is-open) > .Dropdown-control {
  box-shadow: none;
}

.unifiedNav-searchDropdown .Dropdown-root:not(.is-disabled) .Dropdown-control:hover {
  background-color: transparent;
}

.unifiedNav-searchDropdown .Dropdown-control {
  border: transparent;
  background: transparent;
  padding-left: 4px;
  padding-right: 32px;
  color: #4a4a4a;
  position: relative;
  box-sizing: border-box;
}

.unifiedNav-searchDropdown .Dropdown-control svg path {
  fill: #4a4a4a;
}

.unifiedNav-searchDropdown .Dropdown-control:hover .contentTypeLabel {
  color: #ff0089;
}

.unifiedNav-searchDropdown .Dropdown-control:hover svg path {
  fill: #ff0089;
}

.unifiedNav-searchDropdown .Dropdown-control:hover .Dropdown-arrow {
  border-top-color: #ff0089;
}

.unifiedNav-searchDropdown .Dropdown-menu {
  padding: 18px 0;
  border-radius: 4px;
  min-width: 210px;
  box-shadow: -3px -1px 1px -2px rgba(0, 0, 0, 0.12), 1px 1px 5px 0 rgba(0, 0, 0, .2);
  overflow: visible;
  max-height: none;
  font-size: 14px;
  position: absolute;
  background-color: white;
  margin-left: -18px;
  margin-top: 10px;
}

.unifiedNav-searchDropdown .Dropdown-option {
  padding: 6px 10px;
}
.unifiedNav-searchDropdown .Dropdown-option:hover,
.unifiedNav-searchDropdown .Dropdown-option:focus {
  background-color: #f2f9fc;
  color: #333;
}

.unifiedNav-searchDropdown .Dropdown-option:hover .contentTypeLabel,
.unifiedNav-searchDropdown .Dropdown-option:focus .contentTypeLabel {
  color: #ff0089;
}

.unifiedNav-searchDropdown .Dropdown-option:hover svg path,
.unifiedNav-searchDropdown .Dropdown-option:focus svg path {
  fill: #ff0089;
}

.unifiedNav-searchDropdown .Dropdown-option.is-selected,
.unifiedNav-searchDropdown .Dropdown-option.is-focused {
  background-color: #ffffff;
}

.unifiedNav-searchDropdown select {
  border: none;
  background-color: transparent;
  border-radius: 0;
  height: 100%;
  font-size: 14px;
  padding: 0 20px 0 8px;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 64 64"><g><path fill="black" d="M32,39.6L7.9,15.4c-1.8-1.8-4.7-1.8-6.5,0c-1.8,1.8-1.8,4.8,0,6.6l27.2,27.3c1,1,2.2,1.4,3.5,1.3   c1.3,0.1,2.5-0.4,3.5-1.3l27.2-27.3c1.8-1.8,1.8-4.8,0-6.6c-1.8-1.8-4.7-1.8-6.5,0L32,39.6z"/></g></svg>');
  background-position: calc(100% - 8px) calc(1em / 2 + 2px);
  background-size: 8px 8px;
  background-repeat: no-repeat;
}

.unifiedNav-searchDropdown select:active {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 64 64"><g><path fill="black" d="M32,25l24.1,24.3c1.8,1.8,4.7,1.8,6.5,0c1.8-1.8,1.8-4.8,0-6.6L35.5,15.3c-1-1-2.2-1.4-3.5-1.3   c-1.3-0.1-2.5,0.4-3.5,1.3L1.4,42.6c-1.8,1.8-1.8,4.8,0,6.6c1.8,1.8,4.7,1.8,6.5,0L32,25z"/></g></svg>');
  outline: 0;
  box-shadow: none;
}

.unifiedNav-searchDropdown select::-ms-expand {
  display: none;
}

.unifiedNav-searchDropdown select:-moz-focusring {
  color: transparent;
  text-shadow: black;
}

/* Prominent search dropdown  */

.unifiedNav-searchDropdown-experiment {
  height: 40px;
}

.unifiedNav-searchDropdown-experiment .Dropdown-placeholder .contentClassIcon {
  display: none;
}

.unifiedNav-searchDropdown-experiment .contentTypeLabel {
  white-space: nowrap;
  color: #1f2830;
}

.unifiedNav-searchDropdown-experiment .Dropdown-root.is-open .Dropdown-placeholder.is-selected .contentTypeLabel {
  color: #0066cc
}

.unifiedNav-searchDropdown-experiment .Dropdown-root.is-open .Dropdown-arrow-wrapper svg path {
  fill: #0066cc
}

.unifiedNav-searchDropdown-experiment .Dropdown-root.is-focused:not(.is-open) > .Dropdown-control {
  box-shadow: none;
}

.unifiedNav-searchDropdown-experiment .Dropdown-root:not(.is-disabled) .Dropdown-control:hover {
  background-color: transparent;
}

.unifiedNav-searchDropdown-experiment .Dropdown-control {
  width: auto;
  padding-left: 32px;
  padding-right: 16px;
  color: #1f2830;
  display: flex;
  flex: 1;
  align-items: center;
}

.unifiedNav-searchDropdown-experiment .Dropdown-control svg path {
  fill: #1f2830;
}

.unifiedNav-searchDropdown-experiment .Dropdown-control .contentTypeLabel {
  font-size: 16px;
  z-index: 10;
}

.unifiedNav-searchDropdown-experiment .Dropdown-control .Dropdown-arrow-wrapper {
  font-size: 28px;
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 10px;
  z-index: 10;
}

.unifiedNav-searchDropdown-experiment .Dropdown-control:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.unifiedNav-searchDropdown-experiment .Dropdown-control:hover .contentTypeLabel {
  color: #0066cc
}

.unifiedNav-searchDropdown-experiment .Dropdown-control:hover svg path {
  fill: #0066cc
}

.unifiedNav-searchDropdown-experiment .Dropdown-control:hover .Dropdown-arrow-wrapper {
  border-top-color: #0066cc
}

.unifiedNav-searchDropdown-experiment .Dropdown-menu {
  margin-top: 7px;
  z-index: 1000;
}

.unifiedNav-searchDropdown-experiment .Dropdown-option:hover .contentTypeLabel, .unifiedNav-searchDropdown-experiment .Dropdown-option:focus .contentTypeLabel {
  color: #0066cc
}

.unifiedNav-searchDropdown-experiment .Dropdown-option:hover svg path, .unifiedNav-searchDropdown-experiment .Dropdown-option:focus svg path {
  fill: #0066cc
}
